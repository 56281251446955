@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Mulish&display=swap');

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-fix {
  padding-top: 60px;
}

h1 {
  font-family: 'Merriweather', serif;
}

.headShot {
  height: 160px;
}

.studies {
  background-color: #ffffcc;
}

.students {
  background-color: #99cccc;
}

.alumni {
  background-color: #cccc99;
}

.contact {
  background-color: #243f52;
}

.footerContact {
  background-color:lightgrey;
}

.footerContact a:link {
  color: #13344b;
  text-decoration: none;
}

.footerContact a:visited {
  color: #13344b;
  text-decoration: none;  
}

.footerContact a:hover {
  color: #ce4c2c;
  text-decoration: none;  
}

.footerContact a:selected {
  color: #b1d6df;
  text-decoration: none;  
}

.logoSbh {
  width: 80%;
}

.cardOut {
  background-color: #e9e9e9;
}

.imgCheck {
  width: 80%;
}